<script>
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'base-counter',
  components: {
    BaseButton,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    rounded: {
      type: [Boolean, String],
      default: false,
    },
  },
  computed: {
    roundedClass() {
      if (typeof this.rounded === 'boolean') {
        return this.rounded ? 'rounded' : undefined
      } else {
        return this.rounded
      }
    },
  },
  methods: {
    increment() {
      this.$emit('input', Math.min(this.max, parseInt(this.value) + 1))
    },
    decrement() {
      this.$emit('input', Math.max(this.min, parseInt(this.value) - 1))
    },
    onInput(value) {
      const parsed = Math.max(this.min, Math.min(this.max, parseInt(value)))
      const isFinite = Number.isFinite(parsed)

      if (isFinite) {
        this.$emit('input', parsed)
      }

      this.$refs.input.value = isFinite ? parsed : ''
    },
    onBlur() {
      if (!this.$refs.input.value) {
        this.$refs.input.value = this.value || this.min
      }
    },
  },
}
</script>

<template>
  <div
    class="inline-flex shrink-0 items-center text-base leading-none text-eonx-neutral-600"
  >
    <base-button
      ref="decrement"
      data-testid="button-counter-decrement"
      :disabled="disabled || min === value"
      :size="20"
      icon="minus"
      alt="Decrease quantity by one"
      class="shrink-0 rounded-full"
      @click="decrement"
    />
    <input
      ref="input"
      data-testid="input-counter"
      class="mx-[5px] h-7 w-7 shrink-0 rounded-md border border-solid bg-white text-center font-bold leading-none text-eonx-neutral-600 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none focus:border-primary disabled:text-eonx-neutral-600 sm:mx-2.5 sm:h-10 sm:w-10"
      :class="roundedClass"
      :disabled="disabled"
      :value="value"
      :aria-label="`Quantity ${value}`"
      type="number"
      @input="onInput($event.target.value)"
      @blur="onBlur"
    />
    <base-button
      ref="increment"
      data-testid="button-counter-increment"
      :disabled="disabled || max === value"
      :size="20"
      alt="Increase quantity by one "
      icon="plus"
      class="shrink-0 rounded-full"
      @click="increment"
    />
  </div>
</template>
